import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ImageList from '@mui/material/ImageList';
import ImageCard from './ImageCard';
import ImagesFilters from './ImagesFilters';
import { useFavorites } from '../contexts/FavoritesContext';
import { filterPlantsPhotos } from '../helpers';

const CartImageGrid = ({user}) => {
  const navigate = useNavigate();
  const { favorites } = useFavorites();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [filteredFavorites, setFilteredFavorites] = useState(favorites);

  const goHome = () => {
    navigate("/home");
  }

  const resetFilters = () =>
    setFilters({ ...INITIAL_FILTERS })

  useEffect(() => {
    setFilteredFavorites(filterPlantsPhotos(favorites, filters));
  }, [favorites, filters])

	return (
    <>
      <div>
        <h3>
          <Breadcrumbs>
            <Link sx={{fontFamily: 'Lato'}} underline="hover" color="inherit" onClick={()=>goHome()} href="#">
              HOME
            </Link>
            <Typography sx={{fontFamily: 'Lato'}}>Cart</Typography>
          </Breadcrumbs>
        </h3>
      </div>
      <ImagesFilters
        search={false}
        defaultFiltersOpen={true}
        resetFilters={resetFilters}
        filters={filters}
        setFilters={setFilters} />

      <ImageList variant="masonry" cols={3} gap={8}>
          {filteredFavorites.map((favorite) => (
              <ImageCard
                  key={`plant-photo-${favorite.plantPhotoId}`}
                  page="Favorites"
                  user={user}
                  data={favorite} />
          ))}
      </ImageList>
    </>
	)

}

const INITIAL_FILTERS = {
  startDate: null,
  endDate: null,
  plantId: '%2A/%2A',
  yard: '',
  sort: 'newest',
  label: 'Most Recent Photos',
}

export default CartImageGrid;